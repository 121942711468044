import * as State from 'BaxterScript/version/web/core/State';
import * as Cookie from 'BaxterScript/helper/browser/Cookie';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { Config } from 'BaxterScript/types/Config';
import { Cmd } from 'BaxterScript/helper/queue/Queue';
import { LifecycleQueue } from 'BaxterScript/helper/queue/LifecycleQueue';

export const webpackExclude = (config: Config): boolean => !config.app?.consent?.enabled;

let delayedTrackingScripts: () => void;

const lifecycleQueue = new LifecycleQueue('CONSENT');

const removeLegacyCookie = () => {
  const name = 'OTAdditionalConsentString';
  if (Cookie.get(name)) {
    Cookie.remove(name);
  }
};

const bootstrap = () => {
  removeLegacyCookie();
};

const setUserConsent = async (onetrustActiveGroups?: string[] | boolean) => {
  try {
    console.info('[SLOTS][CONSENT][SETUSERCONSENT]', onetrustActiveGroups);
    State.setUserConsentResolved();
    if (globalThis.OptanonActiveGroups || globalThis.OnetrustActiveGroups || Array.isArray(onetrustActiveGroups)) {
      newRelicMetrics.reportMetric(NewRelicMetric.CONSENT_RESOLVED, {
        groups: JSON.stringify(
          globalThis.OptanonActiveGroups || globalThis.OnetrustActiveGroups || onetrustActiveGroups
        ),
      });
      if (
        globalThis.OnetrustActiveGroups?.match(/C0002,/) ||
        (Array.isArray(onetrustActiveGroups) && onetrustActiveGroups.includes('C0002'))
      ) {
        delayedTrackingScripts();
      }
      if (
        globalThis.OptanonActiveGroups?.match(/STACK42,/) ||
        globalThis.OnetrustActiveGroups?.match(/C0004,/) ||
        (Array.isArray(onetrustActiveGroups) && onetrustActiveGroups.includes('C0004'))
      ) {
        State.setUserConsentGiven(true);
      } else {
        State.setUserConsentGiven(false);
      }
    } else {
      console.debug('[SLOTS][CONSENT][SETUSERCONSENT] missing active groups');
      newRelicMetrics.reportMetric(NewRelicMetric.CONSENT_MISSING_ACTIVE_GROUPS);
      State.setUserConsentGiven(false);
    }
    await lifecycleQueue.process();
  } catch (error) {
    console.error('[SLOTS][CONSENT][SETUSERCONSENT]', error);
    newRelicMetrics.reportError(NewRelicError.CONSENT_SET_USER_CONSENT, { message: (error as Error).message });
  }
};

const isUserConsentGiven = () => State.isUserConsentGiven();

const delayPageChange = (cmd: Cmd) => lifecycleQueue.delayPageChange(cmd, !State.isUserConsentResolved());

const delaySet = (cmd: Cmd) => lifecycleQueue.delaySet(cmd, !State.isUserConsentResolved());

const delaySetSpecificContainers = (cmd: Cmd) =>
  lifecycleQueue.delaySetSpecificContainers(cmd, !State.isUserConsentResolved());

const delayPageParams = (cmd: Cmd) => lifecycleQueue.delayPageParams(cmd, !State.isUserConsentResolved());

const setDelayedTrackingScripts = (bootstrapFn: () => void) => {
  console.info('[SLOTS][CONSENT][SETDELAYEDTRACKINGSCRIPTS]', bootstrapFn);
  delayedTrackingScripts = bootstrapFn;
};

// eslint-disable-next-line import/no-default-export
export default {
  bootstrap,
  setUserConsent,
  delaySet,
  delaySetSpecificContainers,
  delayPageChange,
  delayPageParams,
  setDelayedTrackingScripts,
  isUserConsentGiven,
};
