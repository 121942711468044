import * as Html from 'BaxterScript/helper/browser/Html';
import * as State from 'BaxterScript/version/web/core/State';
import * as Provider from 'BaxterScript/version/web/core/Provider';
import Style from 'BaxterScript/version/web/feature/Style';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { Callbacks, Slot } from 'BaxterScript/types/Slot';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import ninjaMetrics from 'BaxterScript/helper/metrics/NinjaMetrics';
import { NinjaMetric } from 'BaxterScript/helper/metrics/NinjaMetric';
import Placeholder from 'BaxterScript/version/web/feature/Placeholder';
import { calculateElapsedTime } from 'BaxterScript/helper/time/TimeElapsedEvaluate';
import LazyLoad from 'BaxterScript/version/web/feature/LazyLoad';
import TimerRefresh from 'BaxterScript/version/web/feature/TimerRefresh';
import Fallback from 'BaxterScript/version/web/feature/Fallback';
import * as Interstitial from 'BaxterScript/version/web/feature/Interstitial';
import * as Objects from 'BaxterScript/helper/object/Object';
import * as Condition from 'BaxterScript/helper/query/Condition';
import Autoplay from 'BaxterScript/version/web/feature/Autoplay';
import Sticky from 'BaxterScript/version/web/feature/sticky/Sticky';
import { ContainerType } from 'BaxterScript/types/ContainerType';
import { Providers as ProviderIds } from 'BaxterScript/version/web/config/Providers';
import { Features } from 'BaxterScript/version/web/config/Features';
import { getConfigById, getRuleCondition } from 'BaxterScript/helper/config/Config';
import { FeatureFlags } from 'BaxterScript/version/web/config/Flags';
import Consent from 'BaxterScript/version/web/feature/Consent';

const getConditionParams = (refreshCount: number, params: TargetingParams = {}) =>
  Objects.clone({
    ...State.getUser(),
    refreshCount,
    ...params,
  });

const removeSlots = (containers: ContainerType[], clear: boolean): void => {
  Provider.remove(
    containers.map((container) => container.state.slot!),
    clear
  );
  containers.forEach((container) => {
    const slot = container.state.slot!;
    if (LazyLoad) {
      LazyLoad.remove(container.state.htmlElement as HTMLElement, slot);
    }
    if (Sticky) {
      Sticky.remove(container.state.htmlElement as HTMLElement, slot);
    }
    if (Autoplay) {
      Autoplay.remove(slot);
    }
    if (TimerRefresh) {
      TimerRefresh.remove(slot);
    }
    if (Fallback) {
      Fallback.remove(container);
    }
    // eslint-disable-next-line no-param-reassign
    container.state.slot = undefined;
    Html.hide(slot.innerHtmlElement);
    if (Style) {
      Style.removeClass(slot.innerHtmlElement, slot.pageId, slot.containerId, slot.id);
    }
    if (Placeholder) {
      Placeholder.apply(slot.pageId, container.config, Objects.clone(State.getPageParams()));
    }
  });
};

const findSlot = (
  pageId: string,
  container: ContainerType,
  params: TargetingParams,
  refreshCount: number
): string | undefined =>
  container.config.slots
    .filter((slotId) => !Fallback || (Fallback && !Fallback.alreadyApplied(container, slotId)))
    .find((slotId) =>
      Condition.query(
        getRuleCondition(globalThis.Baxter.config, pageId, container.config.id, slotId),
        getConditionParams(refreshCount, params)
      )
    );

export const remove = (containers: Record<string, ContainerType>, clear: boolean): void => {
  console.info('[SLOTS][CONTAINER][REMOVE]', containers, clear);
  const containersWithSlots = Object.values(containers).filter((container) => container.state.slot);
  removeSlots(containersWithSlots, clear);
  Object.keys(containers).forEach((containerId) => {
    const container = Html.getElementById(containerId);
    if (container) {
      Html.clear(container);
      Html.clearInlineStyles(container);
    }
    const containerAfter = Html.getElementById(`${containerId}-after`);
    if (containerAfter) {
      Html.clear(containerAfter);
      Html.clearInlineStyles(containerAfter);
    }
  });
};

const noMatchingSlot = (pageId: string, containerId: string, source: string) => {
  console.debug(`[SLOTS][CONTAINER][SET] ${pageId} ${containerId} NO MATCHING SLOT`);
  newRelicMetrics.reportMetric(NewRelicMetric.CONTAINER_NO_MATCHING_SLOT, { containerId, source });
};

const containerDivNotFound = (
  containerId: string,
  pageId: string,
  slotId: string,
  params: TargetingParams,
  source: string
) => {
  console.error(`[SLOTS][CONTAINER][SET] ${containerId} CONTAINER NOT FOUND`);
  newRelicMetrics.reportError(NewRelicError.CONTAINER_DIV_NOT_FOUND, {
    pageId,
    containerId,
    slotId,
    params,
    source,
  });
};

const setInner = (containerHtmlElement: HTMLElement, innerId: string) => {
  const inner = document.createElement('div');
  inner.id = innerId;
  Html.addClass(inner, 'baxter-inner');
  containerHtmlElement.appendChild(inner);
  Html.addClass(containerHtmlElement, 'baxter-container');
  Html.show(containerHtmlElement);
  return inner;
};

const containerInnerDivNotFound = (
  containerId: string,
  pageId: string,
  slotId: string,
  innerId: string,
  params: TargetingParams,
  source: string
) => {
  console.error(`[SLOTS][CONTAINER][SET] ${containerId} INNER NOT FOUND`);
  newRelicMetrics.reportError(NewRelicError.CONTAINER_INNER_DIV_NOT_FOUND, {
    pageId,
    containerId,
    slotId,
    innerId,
    params,
    source,
  });
};

const recentlySet = (date) => {
  if (!date) return false;
  const SECS = 1000 * 2;
  const secsAgo = Date.now() - SECS;

  return date > secsAgo;
};

const containerSetTooRecently = (containerId: string, pageId: string, slotId: string, params: TargetingParams) => {
  console.error(`[SLOTS][CONTAINER][SET] ${containerId} CONTAINER SET TOO RECENTLY`);
  newRelicMetrics.reportError(NewRelicError.CONTAINER_SET_TOO_RECENTLY, {
    pageId,
    containerId,
    slotId,
    params,
  });
};

const handleVideo = (slot: Slot, container: ContainerType) => {
  Html.show(slot.innerHtmlElement);
  if (Placeholder) {
    Placeholder.remove(slot.pageId, slot.containerId);
  }
  if (Sticky) {
    Sticky.apply(container.state.htmlElement as HTMLElement, slot);
  }
  if (Autoplay) {
    Autoplay.apply(slot);
  }
};

const handleEmpty = (
  container: ContainerType,
  slot: Slot,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean,
  hasVideo: undefined | boolean,
  isEmpty: boolean
) => {
  if (Fallback && Fallback.apply(container, slot, setSlot)) {
    return true;
  }
  if (hasVideo) {
    handleVideo(slot, container);
  } else if (FeatureFlags.EUADS_6444(slot) && TimerRefresh) {
    TimerRefresh.apply(container, slot, setSlot, isEmpty);
  }
  return false;
};

const handleNonEmpty = (
  parameters: Record<string, unknown>,
  slot: Slot,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean,
  source: string,
  container: ContainerType
) => {
  ninjaMetrics.reportMetric(NinjaMetric.ADVERTISEMENT_DISPLAYED, {
    ...parameters,
    ad_slot_id: slot.id,
    ad_request_source: source,
    provider_id: slot.provider,
  });
  Html.show(slot.innerHtmlElement);
  if (Placeholder) {
    Placeholder.remove(slot.pageId, slot.containerId);
  }
  if (Sticky) {
    Sticky.apply(container.state.htmlElement as HTMLElement, slot);
  }
  if (Autoplay) {
    Autoplay.apply(slot);
  }
  if (TimerRefresh) {
    TimerRefresh.apply(container, slot, setSlot);
  }
  return false;
};

const createCallbacks = (
  container: ContainerType,
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
): Callbacks => ({
  impressionViewableCallback: (slotId: string, source: string, parameters: Record<string, unknown>) => {
    console.info('[SLOTS][CONTAINER][IMPRESSIONVIEWABLECALLBACK]', parameters);
    ninjaMetrics.reportMetric(NinjaMetric.ADVERTISEMENT_VIEWED, {
      ...parameters,
      ad_slot_id: slotId,
      ad_request_source: source,
    });
    newRelicMetrics.reportMetric(NewRelicMetric.AD_IMPRESSION, {
      slotId,
      source,
      adblockEnabled: State.getAdblockStatus(),
      ...(Consent ? { userConsentGiven: State.isUserConsentGiven() } : {}),
    });
  },
  slotRenderEndedCallback: (
    source: string,
    slot: Slot,
    isEmpty: boolean,
    hasVideo?: boolean,
    parameters: Record<string, unknown> = {}
  ): boolean => {
    console.info('[SLOTS][CONTAINER][SLOTRENDERENDEDCALLBACK]', source, slot, isEmpty, hasVideo, parameters);
    let creationTillRenderedTime;
    if (!slot.alreadyRendered) {
      // eslint-disable-next-line no-param-reassign
      slot.alreadyRendered = true;
      creationTillRenderedTime = calculateElapsedTime(slot.creationDate!);
    }
    newRelicMetrics.reportMetric(NewRelicMetric.AD_RENDERED, {
      pageId: slot.pageId,
      containerId: slot.containerId,
      slotId: slot.id,
      isEmpty,
      providerId: slot.provider,
      creationTillRenderedTime,
      lazyLoad: slot?.[Features.LAZY_LOAD]?.config?.enabled,
      source,
      adblockEnabled: State.getAdblockStatus(),
      ...(Consent ? { userConsentGiven: State.isUserConsentGiven() } : {}),
    });
    if (isEmpty) {
      return handleEmpty(container, slot, setSlot, hasVideo, isEmpty);
    }
    return handleNonEmpty(parameters, slot, setSlot, source, container);
  },
});

export const setSlot = (
  source: string,
  pageId: string,
  container: ContainerType,
  params: TargetingParams,
  skipContainerRecentlySetCheck: boolean = false
): boolean => {
  try {
    console.info('[SLOTS][CONTAINER][SET]', source, pageId, container);
    const containerId = container.config.id;
    newRelicMetrics.reportMetric(NewRelicMetric.CONTAINER_SET_SLOT, { containerId, source });
    const existingSlot = container.state.slot;
    const refreshCount = existingSlot?.refreshCount || 0;
    const slotId = findSlot(pageId, container, params, refreshCount);
    // eslint-disable-next-line no-param-reassign
    params.slot = slotId;

    if (!slotId) {
      noMatchingSlot(pageId, containerId, source);
      return false;
    }

    const containerHtmlElement = Html.getElementById(containerId);
    if (!containerHtmlElement) {
      containerDivNotFound(containerId, pageId, slotId, params, source);
      if (existingSlot) {
        removeSlots([container], false);
      }
      return false;
    }
    if (!container.state.htmlElement) {
      // eslint-disable-next-line no-param-reassign
      container.state.htmlElement = containerHtmlElement;
    }

    if (!skipContainerRecentlySetCheck && recentlySet(container.state.setSlotDate)) {
      containerSetTooRecently(containerId, pageId, slotId, params);
      return false;
    }
    // eslint-disable-next-line no-param-reassign
    container.state.setSlotDate = Date.now();

    const providerId = getConfigById(
      globalThis.Baxter.config?.slots?.provider,
      pageId,
      containerId,
      slotId
    ) as ProviderIds;
    if (slotId !== existingSlot?.id) {
      if (existingSlot) {
        removeSlots([container], false);
      }
      const newSlot = Provider.initialize(providerId, pageId, containerId, slotId, params) as Slot | undefined;
      if (!newSlot) {
        return false;
      }
      newSlot.id = slotId;
      newSlot.pageId = pageId;
      newSlot.containerId = containerId;
      newSlot.innerId = `${containerId}-inner`;
      newSlot.provider = providerId;
      newSlot.params = params;
      newSlot.refreshCount = refreshCount;
      newSlot.creationDate = Date.now();

      if (!container.state.alreadyCreatedInner) {
        // eslint-disable-next-line no-param-reassign
        container.state.alreadyCreatedInner = true;
        newSlot.innerHtmlElement = setInner(containerHtmlElement, newSlot.innerId);
      } else {
        const innerHtmlElement = Html.getElementById(newSlot.innerId);
        if (!innerHtmlElement) {
          containerInnerDivNotFound(containerId, pageId, slotId, newSlot.innerId, params, source);
          if (existingSlot) {
            removeSlots([container], false);
          }
          // eslint-disable-next-line no-param-reassign
          return false;
        }
        newSlot.innerHtmlElement = innerHtmlElement;
      }
      // eslint-disable-next-line no-param-reassign
      container.state.slot = newSlot as Slot;

      if (Style) {
        if (existingSlot) {
          console.debug(
            `[SLOTS][CONTAINER][SET] Style.removeClass`,
            existingSlot.pageId,
            existingSlot.containerId,
            existingSlot.id
          );
          Style.removeClass(
            existingSlot.innerHtmlElement,
            existingSlot.pageId,
            existingSlot.containerId,
            existingSlot.id
          );
        }
        console.debug(`[SLOTS][CONTAINER][SET] Style.addClass`, pageId, containerId, slotId);
        Style.addClass(newSlot.innerHtmlElement, pageId, containerId, slotId);
      }

      const callbacks = createCallbacks(container, setSlot);

      if (Interstitial?.apply?.(container.state.slot, callbacks)) {
        // eslint-disable-next-line no-param-reassign
        container.state.slot = undefined;
        newRelicMetrics.reportMetric(NewRelicMetric.CONTAINER_SLOT_INTERSTITIAL_APPLIED, { containerId, source });
        return false;
      }

      if (!container.state.alreadySetSlot) {
        // eslint-disable-next-line no-param-reassign
        container.state.alreadySetSlot = true;
        if (LazyLoad && LazyLoad.apply(container, callbacks, setSlot)) {
          newRelicMetrics.reportMetric(NewRelicMetric.CONTAINER_SLOT_LAZY_LOAD_APPLIED, { containerId, source });
          return false;
        }
      }
      const success = Provider.create(newSlot as Slot, callbacks);
      if (!success) {
        return false;
      }
    }
    newRelicMetrics.reportMetric(NewRelicMetric.CONTAINER_LOADABLE, { containerId, source });
    return true;
  } catch (err) {
    console.error(`[SLOTS][CONTAINER][CREATE] ${container.config.id}`, err);
    newRelicMetrics.reportError(NewRelicError.CONTAINER_SET_SLOT_ERROR, { message: (err as Error).message, source });
    return false;
  }
};
