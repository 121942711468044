import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import {
  GoogleAdsInitializedSizesItem,
  GoogleAdsSlot,
  GoogleSlotExternal,
  Callbacks,
  GoogleAdsInitializedBidders,
  Initialized,
} from 'BaxterScript/types/Slot';
import { Config } from 'BaxterScript/types/Config';
import {
  GoogleAdsApsConfig,
  GoogleAdsConfig,
  GoogleAdsPathConfig,
  GoogleAdsPrebidConfig,
  GoogleAdsSizesConfig,
} from 'BaxterScript/types/ProviderSlotConfig/GoogleAds';
import { initializeTargeting } from 'BaxterScript/version/web/provider/InitializeTargeting';
import * as Strings from 'BaxterScript/helper/string/String';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import {
  googleImpressionViewableCallback,
  googleSlotRenderEndedCallback,
  googleSlotRequestedCallback,
  googleSlotResponseReceivedCallback,
} from 'BaxterScript/version/web/provider/googleads/GoogleAdsEventsCallbacks';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import * as Objects from 'BaxterScript/helper/object/Object';
import * as State from 'BaxterScript/version/web/core/State';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import {
  GoogleAdsProviderConfig,
  GoogleAdsProviderConfigSettings,
} from 'BaxterScript/types/ProviderGlobalConfig/GoogleAdsProviderConfig';
import Bidders from './bidders/GoogleAdsBidders';

export const id = Providers.GOOGLE_ADS;

export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  );

export const init = () => {
  console.info('[SLOTS][GOOGLEADS][INIT]');
  globalThis.googletag = globalThis.googletag || { cmd: [] };
  globalThis.googletag.cmd = globalThis.googletag.cmd || [];

  if (Bidders) {
    Bidders.init();
  }
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEADS][DEPENDENCIES]');
  const dependencyList: { id: string; url: string }[] = [];
  if (Bidders) {
    dependencyList.push(...Bidders.dependencies());
  }
  dependencyList.push({
    id: 'gpt',
    url: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
  });
  return {
    id,
    dependencies: dependencyList,
  };
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEADS][LOADED]');
  const providerConfig = globalThis.Baxter.config.providers[id] || {};
  // @ts-ignore
  const googleSettings: Record<string, unknown> = providerConfig.settings || {};
  const { googletag } = globalThis;
  console.debug('[SLOTS][GOOGLEADS][LOADED]', googletag, providerConfig);
  googletag.cmd.push(() => {
    try {
      if (Strings.isString(googleSettings.adsenseBackgroundColor)) {
        console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().set');
        googletag.pubads().set('adsense_background_color', googleSettings.adsenseBackgroundColor);
      }
      if (googleSettings.collapseEmptyDivs === 'after') {
        console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().collapseEmptyDivs');
        googletag.pubads().collapseEmptyDivs();
      }
      if (googleSettings.collapseEmptyDivs === 'before') {
        console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().collapseEmptyDivs true');
        googletag.pubads().collapseEmptyDivs(true);
      }
      console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().enableSingleRequest');
      googletag.pubads().enableSingleRequest();
      console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().disableInitialLoad');
      googletag.pubads().disableInitialLoad();
      console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.enableServices');
      googletag.enableServices();
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][LOADED]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
        command: '[LOADED]',
        message: (e as Error).message,
      });
      throw e;
    }
  });

  if (Bidders) {
    Bidders.loaded();
  }
};

const setSlotSizes = (sizes: GoogleAdsInitializedSizesItem[], external: GoogleSlotExternal) => {
  console.info('[SLOTS][GOOGLEADS][SETSLOTSIZES]', sizes, external);
  const { googletag } = globalThis;
  const map = googletag.sizeMapping();
  sizes.forEach((size) => {
    if (size.viewport) {
      map.addSize(size.viewport, size.slot || []);
    }
  });
  const mapping = map.build();
  external.defineSizeMapping(mapping);
};

const setSlotTargeting = (keyValues: TargetingParams, external: GoogleSlotExternal) => {
  console.info('[SLOTS][GOOGLEADS][SETSLOTTARGETING]', keyValues, external);
  const { googletag } = globalThis;
  for (const key of Object.keys(keyValues)) {
    const val = keyValues[key];
    const valueToCheck = googletag.pubads().getTargeting(key);
    if (valueToCheck.length === 0) {
      external.setTargeting(key, val);
    } else if (valueToCheck.length === 1 && !Array.isArray(val) && valueToCheck[0] !== val) {
      external.setTargeting(key, val);
    } else if (Array.isArray(val) && val.filter((x) => valueToCheck.includes(x)).length !== val.length) {
      external.setTargeting(key, val);
    }
  }
};

export const initialize = (
  pageId: string,
  containerId: string,
  slotId: string,
  params: TargetingParams
): Initialized<GoogleAdsSlot> => {
  console.info('[SLOTS][GOOGLEADS][INITIALIZE]', pageId, containerId, slotId, params);
  const slotConfig = globalThis.Baxter.config.slots?.providerSettings?.[id] as GoogleAdsConfig;
  const providerConfig = globalThis.Baxter.config.providers[id];

  let targeting = {};
  if (slotConfig?.targeting) {
    targeting = getConfigById(slotConfig?.targeting || {}, pageId, containerId, slotId);
  }
  const initializedTargeting = {
    targeting: {},
  };
  initializeTargeting(initializedTargeting, slotConfig, providerConfig.settings, pageId, containerId, slotId, params);

  let path = {};
  let initializedPath = '';
  if (slotConfig?.path) {
    const { accountId } = providerConfig.settings;
    const pathPrefixMap = providerConfig.settings.pathPrefix;
    const pathPrefix = Strings.parseMap(pathPrefixMap || [], params);
    path = getConfigById(slotConfig?.path, pageId, containerId, slotId) || {};
    const parsedPath = Strings.parseMap((path as GoogleAdsPathConfig).map || [], params);
    initializedPath = pathPrefix ? `/${accountId}/${pathPrefix}/${parsedPath}` : `/${accountId}/${parsedPath}`;
  }

  let sizes = {};
  let initializedSizes: GoogleAdsInitializedSizesItem[] = [];
  if (slotConfig?.sizes) {
    const defaultSizes = [{ viewport: [1, 1], slot: [[1, 1]] }];
    sizes = getConfigById(slotConfig?.sizes, pageId, containerId, slotId) || {};
    initializedSizes = ((sizes as GoogleAdsSizesConfig).map || defaultSizes)
      .map((size) => {
        // eslint-disable-next-line no-param-reassign
        if (!size.slot || size.slot.length === 0) size.slot = defaultSizes[0].slot;
        size.slot.forEach((item, index) => {
          if (Strings.isString(item) && item.includes('x')) {
            const widthHeight = item.split('x');
            const isIntegers = widthHeight.every((element) => Strings.isNumeric(element));
            if (isIntegers) {
              // eslint-disable-next-line no-param-reassign
              size.slot[index] = widthHeight.map((element) => parseInt(element, 10));
            }
          }
        });
        return size;
      })
      .reverse();
  }

  let prebid = {} as GoogleAdsPrebidConfig;
  if (slotConfig?.prebid) {
    prebid = getConfigById(slotConfig?.prebid, pageId, containerId, slotId) || {};
  }

  let aps = {} as GoogleAdsApsConfig;
  if (slotConfig?.aps) {
    aps = getConfigById(slotConfig?.aps, pageId, containerId, slotId) || {};
  }

  const biddersConfig = { prebid, aps };

  let bidders = {} as GoogleAdsInitializedBidders;
  if (Bidders && Bidders.enabledSomeBidderForSlot(biddersConfig)) {
    bidders = Bidders.initialize(biddersConfig);
  }

  return {
    [id]: {
      providerConfig,
      config: {
        sizes,
        path,
        targeting,
        ...biddersConfig,
      },
      initialized: {
        sizes: initializedSizes,
        path: initializedPath,
        targeting: initializedTargeting.targeting as TargetingParams,
        bidders,
      },
      state: {},
    },
  };
};

const googleCreate = (googleSlot: GoogleAdsSlot, callbacks: Callbacks): void => {
  const { googletag } = globalThis;
  googletag.cmd.push(() => {
    try {
      console.debug(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot}`);
      const { containerId, pageId, innerId, id: slotId } = googleSlot;
      const sizes = googleSlot[id].initialized.sizes?.[0]?.slot;
      const external: GoogleSlotExternal = googletag.defineSlot(googleSlot[id].initialized.path, sizes, innerId);
      if (external) {
        // eslint-disable-next-line no-param-reassign
        googleSlot[id].state.external = external;
        console.debug(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} external.addService`);
        external.addService(googletag.pubads());
        setSlotSizes(googleSlot[id].initialized.sizes || [], external);
        setSlotTargeting(googleSlot[id].initialized.targeting || {}, external);
        if (callbacks.slotRequestedCallback) {
          googletag
            .pubads()
            .addEventListener(
              'slotRequested',
              googleSlotRequestedCallback(
                external,
                { ad_unit_id: googleSlot[id].initialized.path },
                callbacks.slotRequestedCallback
              )
            );
        }
        if (callbacks.slotResponseReceivedCallback) {
          googletag
            .pubads()
            .addEventListener(
              'slotResponseReceived',
              googleSlotResponseReceivedCallback(
                external,
                { ad_unit_id: googleSlot[id].initialized.path },
                callbacks.slotResponseReceivedCallback
              )
            );
        }
        googletag
          .pubads()
          .addEventListener(
            'slotRenderEnded',
            googleSlotRenderEndedCallback(
              googleSlot,
              external,
              { ad_unit_id: googleSlot[id].initialized.path },
              callbacks.slotRenderEndedCallback
            )
          );
        googletag
          .pubads()
          .addEventListener(
            'impressionViewable',
            googleImpressionViewableCallback(
              googleSlot,
              external,
              { ad_unit_id: googleSlot[id].initialized.path },
              callbacks.impressionViewableCallback
            )
          );
      } else {
        console.error(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} could not get external`);
        newRelicMetrics.reportError(NewRelicError.GOOGLEADS_NO_EXTERNAL, {
          providerId: id,
          slotId,
          containerId,
          pageId,
          path: googleSlot[id].initialized.path,
        });
        throw new Error('[SLOTS][GOOGLEADS] could not get external');
      }
    } catch (e) {
      console.error(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id}`, e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
        command: '[CREATE]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
};

export const create = (slot: GoogleAdsSlot, callbacks: Callbacks): void => {
  console.info('[SLOTS][GOOGLEADS][CREATE]', slot);
  if (Bidders && Bidders.enabledSomeBidderForSlot(slot[id].config)) {
    console.debug(`[SLOTS][GOOGLEADS][CREATE] ${slot.containerId} ${slot.id} Bidders.create`);
    Bidders.create(slot, googleCreate, callbacks);
  } else {
    console.debug(`[SLOTS][GOOGLEADS][CREATE] ${slot.containerId} ${slot.id} Google.create`);
    googleCreate(slot, callbacks);
  }
};

const googleLoad = (slots: GoogleAdsSlot[]): void => {
  console.info('[SLOTS][GOOGLEADS][GOOGLELOAD]', slots);
  const { googletag } = globalThis;
  googletag.cmd.push(() => {
    try {
      const slotsToRefresh = slots
        .filter((slot) => {
          if (slot[id].state.alreadyRemoved) {
            console.debug('[SLOTS][GOOGLEADS][GOOGLELOAD] slot already removed', slot);
            newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_SLOT_ALREADY_REMOVED, { place: 'load' });
            return false;
          }
          return true;
        })
        .filter((slot) => slot[id].state.external)
        .map((slot) => {
          const { external } = slot[id].state;
          setSlotTargeting(
            {
              ad_request_source: slot[id].state.loadSource,
            },
            external!
          );

          return external;
        });
      if (slotsToRefresh.length) {
        console.debug('[SLOTS][GOOGLEADS][GOOGLELOAD] googletag.pubads().refresh(...)', slotsToRefresh);
        googletag.pubads().refresh(slotsToRefresh);
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][LOAD]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
        command: '[LOAD]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
};

export const load = async (source: string, slots: GoogleAdsSlot[] = []): Promise<void> => {
  console.info('[SLOTS][GOOGLEADS][LOAD]', slots);
  const slotsToLoad = slots.map((slot) => {
    // eslint-disable-next-line no-param-reassign
    slot[id].state.loadSource = source;
    return slot;
  });
  if (Bidders && slotsToLoad.some((slot) => Bidders.enabledSomeBidderForSlot(slot[id].config))) {
    console.debug(`[SLOTS][GOOGLEADS][CREATE] Bidders.load`);
    Bidders.load(slotsToLoad, googleLoad);
  } else {
    console.debug(`[SLOTS][GOOGLEADS][CREATE] Google.load`);
    googleLoad(slotsToLoad);
  }
};

export const remove = (slots: GoogleAdsSlot[], clear: boolean): void => {
  console.info('[SLOTS][GOOGLEADS][REMOVE]', slots);
  slots.forEach((slot) => {
    // eslint-disable-next-line no-param-reassign
    slot[id].state.alreadyRemoved = true;
  });
  const { googletag } = globalThis;
  if (Bidders) {
    console.debug('[SLOTS][GOOGLEADS][REMOVE] Bidders.remove', slots);
    Bidders.remove(slots);
  }
  googletag.cmd.push(() => {
    try {
      if (clear) {
        const destroyed = googletag.destroySlots();
        console.debug(`[SLOTS][GOOGLEADS][REMOVE] clear googletag.destroySlots() result ${destroyed}`);
        if (!destroyed) {
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_DESTROY_SLOTS_IN_REMOVE_WITH_CLEAR_DESTROYED_NOTHING);
        }
      } else {
        const slotsToDestroy = slots.filter((slot) => slot[id].state.external).map((slot) => slot[id].state.external);
        if (slotsToDestroy.length) {
          console.debug('[SLOTS][GOOGLEADS][REMOVE] googletag.destroySlots()');
          const destroyed = googletag.destroySlots(slotsToDestroy);
          console.debug(`[SLOTS][GOOGLEADS][REMOVE] googletag.destroySlots() result ${destroyed}`);
          if (!destroyed) {
            newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_DESTROY_SLOTS_IN_REMOVE_DESTROYED_NOTHING);
          }
        }
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][REMOVE]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
        command: '[REMOVE]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
};

export const setPageTargeting = (params: TargetingParams): void => {
  console.info('[SLOTS][GOOGLEADS][SETPAGETARGETING]');
  const providerSettings = (globalThis.Baxter.config.providers[id] || {}) as GoogleAdsProviderConfig;
  const googleSettings = (providerSettings.settings || {}) as GoogleAdsProviderConfigSettings;
  const { googletag } = globalThis;
  googletag.cmd.push(() => {
    try {
      console.debug('[SLOTS][GOOGLEADS][SETPAGETARGETING] googletag.pubads().clearTargeting()');
      googletag.pubads().clearTargeting();
      console.info('[SLOTS][GOOGLEADS][SETPAGETARGETING]', googleSettings.targeting);
      if (Array.isArray(googleSettings.targeting)) {
        const ranges = globalThis.Baxter.config.app?.ranges;
        const targeting = Objects.parseMap(googleSettings.targeting || [], params, ranges) as TargetingParams;
        Object.keys(targeting).forEach((key) => globalThis.googletag.pubads().setTargeting(key, targeting[key]));
        if (Bidders) {
          Bidders.setPageTargeting(targeting);
        }
      }
      if (params.user_email_sha256 && globalThis.Baxter.config.accountId === 'olxbg') {
        globalThis.googletag.pubads().setPublisherProvidedId(params.user_email_sha256);
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][SETPAGETARGETING]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_COMMAND_ERROR, {
        command: '[SETPAGETARGETING]',
        message: (e as Error).message,
      });
      throw e;
    }
  });
};

export const setPreview = () => {
  console.info('[SLOTS][GOOGLEADS][SETPREVIEW]');
  const searchParams = new URLSearchParams(window.location.search);
  const previewParams = State.getPreview(id);
  if (previewParams) {
    console.debug('[SLOTS][GOOGLEADS][SETPREVIEW] applying preview params', previewParams?.toString());
    [...(previewParams as URLSearchParams).entries()].forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    searchParams.set('baxter_preview_used', '1');
    window.history.replaceState(null, '', `?${searchParams.toString()}`);
    State.setPreview(id, undefined);
  } else if (searchParams.has('google_preview') && !searchParams.has('baxter_preview_used')) {
    State.setPreview(id, searchParams);
  }
};
