import * as CmdQueue from 'BaxterScript/helper/queue/CmdQueue';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import Consent from 'BaxterScript/version/web/feature/Consent';
import Style from 'BaxterScript/version/web/feature/Style';
import TimerRefresh from 'BaxterScript/version/web/feature/TimerRefresh';
import * as Container from 'BaxterScript/version/web/core/Container';
import BreakpointRefresh from 'BaxterScript/version/web/feature/BreakpointRefresh';
import * as Interstitial from 'BaxterScript/version/web/feature/Interstitial';
import { State as StateType } from 'BaxterScript/types/State';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import * as State from './core/State';
import * as Provider from './core/Provider';
import * as LifeCycle from './core/Lifecycle';
import * as Polyfill from './core/Polyfill';
import * as Preview from './core/Preview';
import AdBlock from './feature/AdBlock';
import Cxense from './feature/script/Cxense';
import Scripts from './feature/Scripts';
import Gemius from './feature/script/Gemius';
import Sati from './feature/script/Sati';

globalThis.Baxter = globalThis.Baxter || {};
globalThis.Baxter.queue = globalThis.Baxter.queue || [];

const trackingScriptsBootstrap = () => {
  if (Cxense) Cxense.bootstrap();
  if (Sati) Sati.bootstrap();
  if (Gemius) Gemius.bootstrap();
};

const initialize = async () => {
  Polyfill.bootstrap();
  if (Style) Style.bootstrap();
  if (Consent) {
    Consent.bootstrap();
    Consent.setDelayedTrackingScripts(trackingScriptsBootstrap);
  } else {
    trackingScriptsBootstrap();
  }
  if (AdBlock) AdBlock.bootstrap();
  if (Scripts) Scripts.bootstrap();
  if (TimerRefresh) TimerRefresh.bootstrap(Container.setSlot);
  Interstitial?.bootstrap?.();
  State.bootstrap();
  if (BreakpointRefresh) BreakpointRefresh.bootstrap(Container.setSlot);
  Provider.bootstrap();
  await CmdQueue.init(globalThis.Baxter.queue);
};

/* eslint-disable no-undef */
if (!globalThis.Baxter.initialized || Preview.shouldInitialize()) {
  globalThis.Baxter.initialized = true;
  globalThis.Baxter.config = __CONFIG__;
  // todo: merge into config before runtime
  globalThis.Baxter.config.accountId = __ACCOUNT_ID__;
  globalThis.Baxter.config.appId = __APP_ID__;
  globalThis.Baxter.config.versionId = __VERSION_ID__;
  globalThis.Baxter.config.commitHash = __COMMIT_HASH__;
  globalThis.Baxter.config.environment = __ENVIRONMENT__;
  globalThis.Baxter.config.cdnDomain = __CDN_DOMAIN__;
  globalThis.Baxter.config.apiDomain = __API_DOMAIN__;
  // todo: set in 'baxter-native' provider config (not env var)??
  globalThis.Baxter.config.adsDomain = __ADS_DOMAIN__;

  globalThis.Baxter.state = {
    providers: [],
    page: {
      id: undefined,
      params: {},
    },
    placeholders: {},
    containers: {},
    interstitials: { actions: [], slots: {} },
    user: {
      cxense: {},
      sati: {
        init: false,
      },
      active: true,
      consentResolved: false,
      consentGiven: false,
    },
    app: {
      breakpoints: [],
    },
    previews: {} as Record<Providers, unknown>,
  } as StateType;

  newRelicMetrics.reportMetric(NewRelicMetric.SCRIPT_INITIALIZED);
  console.debug('[SLOTS][INITJS][ENABLED]');
  globalThis.Baxter.consent = Consent
    ? Consent.setUserConsent
    : async () => {
        console.debug('[SLOTS][CONSENT][SETUSERCONSENT] disabled in slots so do nothing');
      };
  globalThis.Baxter.page = LifeCycle.onPageChanged;
  globalThis.Baxter.set = LifeCycle.onSet;
  globalThis.Baxter.clear = LifeCycle.onClear;
  globalThis.Baxter.track = LifeCycle.onPageTrack;
  globalThis.Baxter.setAfterLoaded = LifeCycle.onSetAfterLoaded;
  globalThis.Baxter.setPageParams = LifeCycle.onSetPageParams;

  (async () => {
    try {
      console.info('[SLOTS][INITJS] INIT SCRIPT LOADED', globalThis.Baxter.config.versionId);
      if (await Preview.init()) {
        console.info('[SLOTS][INITJS] PREVIEW SCRIPT LOADED', globalThis.Baxter.config.versionId);
      } else if (globalThis.Baxter.config.app.enabled) {
        console.info('[SLOTS][INITJS] INITIALIZING', globalThis.Baxter.config);
        await initialize();
      }
    } catch (e) {
      console.error('[SLOTS][INITJS]', e);
      newRelicMetrics.reportError(NewRelicError.SCRIPT_ERROR, { message: (e as Error).message });
    }
  })();
}
