import * as Html from 'BaxterScript/helper/browser/Html';
import * as StickyCloseButton from 'BaxterScript/version/web/feature/sticky/StickyCloseButton';
import { Config } from 'BaxterScript/types/Config';
import { Slot, StickySlot } from 'BaxterScript/types/Slot';
import { Features } from 'BaxterScript/version/web/config/Features';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import ninjaMetrics from 'BaxterScript/helper/metrics/NinjaMetrics';
import { convertMinutesToMilliseconds } from 'BaxterScript/helper/time/TimeConvert';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { NinjaMetric } from 'BaxterScript/helper/metrics/NinjaMetric';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import * as Observer from 'BaxterScript/helper/browser/Observer';

export const id = Features.STICKY;

export const webpackExclude = (config: Config): boolean => {
  const settings = config.slots?.settings?.sticky;
  return !(
    (settings?._ && Object.values(settings._).some((item) => !!item?.enabled)) ||
    (settings && Object.values(settings).some((item) => !!item?.enabled))
  );
};

const removeObserver = (containerHtmlElement: HTMLElement, slot: StickySlot) => {
  console.info('[SLOTS][STICKY][removeObserver]');
  Observer.removeObserver(containerHtmlElement, slot[id].state);
};

export const unstick = (slot: StickySlot, innerHtmlElement: HTMLElement) => {
  console.info('[SLOTS][STICKY][UNSTICK]');
  newRelicMetrics.reportMetric(NewRelicMetric.STICKY_UNSTICK);
  // eslint-disable-next-line no-param-reassign
  slot[id].state.sticky = false;

  setTimeout(() => {
    try {
      console.info('[SLOTS][STICKY][REMOVECLOSEBUTTON]');
      const closeButton = document.getElementById(`${slot.innerId}-sticky-close-button`);
      if (closeButton) {
        closeButton.remove();
      }
    } catch (e) {
      console.error('[SLOTS][STICKY][REMOVECLOSEBUTTON][TIMEOUT]', e);
      newRelicMetrics.reportError(NewRelicError.STICKY_REMOVE_CLOSE_BUTTON_TIMEOUT_ERROR, {
        message: (e as Error).message,
      });
    }
  }, 50);

  Html.addStyleToElement(innerHtmlElement, { cssText: slot[id].state.oldStyle });
  const stickyPlaceholder = document.getElementById(`${slot.innerId}-sticky-placeholder`);
  if (stickyPlaceholder) {
    // eslint-disable-next-line no-param-reassign
    innerHtmlElement.classList.value = stickyPlaceholder.classList.value;
    stickyPlaceholder.remove();
  }
};

const getSlotKey = (slot: StickySlot) => `sticky_ad_${slot.pageId}#${slot.containerId}#${slot.id}`;

const addCloseButton = (slot: StickySlot, innerHtmlElement: HTMLElement) => {
  console.info('[SLOTS][STICKY][ADDCLOSEBUTTON]');
  const closeButton = document.createElement('div');
  closeButton.id = `${slot.innerId}-sticky-close-button`;
  closeButton.innerHTML = slot[id].config.closeButton.label ?? '&times;';
  Html.addClass(closeButton, 'baxter-inner-sticky-close-button');
  Html.addStyleToElement(
    closeButton,
    {
      cssText:
        slot[id].config.closeButton.style ??
        'cursor: pointer; opacity: 0.3; font-size: 25px; font-weight: 400; height: 30px;',
    },
    false
  );
  const closeButtonOldOpacity = closeButton.style.opacity;
  Html.addStyleToElement(
    closeButton,
    {
      opacity: 0,
    },
    false
  );
  innerHtmlElement.parentNode?.insertBefore(closeButton, innerHtmlElement);
  const rightLeftBottomTop = StickyCloseButton.calculateRightLeftBottomTop(
    slot[id].config.anchor,
    slot[id].config.closeButton.anchor ?? 'top-right',
    innerHtmlElement.getBoundingClientRect(),
    closeButton.getBoundingClientRect()
  );
  Html.addStyleToElement(closeButton, rightLeftBottomTop, true);
  Html.addStyleToElement(closeButton, { opacity: closeButtonOldOpacity }, false);
  closeButton.addEventListener('click', () => {
    try {
      console.info('[SLOTS][STICKY][CLOSEBUTTONEVENTLISTENER]');
      newRelicMetrics.reportMetric(NewRelicMetric.STICKY_CLOSE);
      ninjaMetrics.reportMetric(NinjaMetric.STICKY_AD_CLOSED, {
        ad_unit_id: slot?.[Providers.GOOGLE_ADS]?.initialized?.path,
      });
      localStorage.setItem(getSlotKey(slot), Date.now().toString());
      // eslint-disable-next-line no-param-reassign
      slot[id].state.stickyClosedByUser = true;
      unstick(slot, innerHtmlElement);
    } catch (e) {
      console.error('[SLOTS][STICKY][CLOSEBUTTONEVENTLISTENER]', e);
      newRelicMetrics.reportError(NewRelicError.STICKY_CLOSE_BUTTON_EVENT_LISTINER_ERROR, {
        message: (e as Error).message,
      });
    }
  });
  if (!slot[id].state.resizeListenerAdded) {
    // eslint-disable-next-line no-param-reassign
    slot[id].state.resizeListenerAdded = true;
    const resizeObserver = new ResizeObserver(() => {
      try {
        console.info('[SLOTS][STICKY][RESIZEOBSERVER]');
        const closeButtonElement = Html.getElementById(`${slot.innerId}-sticky-close-button`);
        if (closeButtonElement) {
          const rightLeftBottomTopOnResize = StickyCloseButton.calculateRightLeftBottomTop(
            slot[id].config.anchor,
            slot[id].config.closeButton?.anchor ?? 'top-right',
            innerHtmlElement.getBoundingClientRect(),
            closeButtonElement.getBoundingClientRect()
          );
          console.debug(
            '[SLOTS][STICKY][RESIZEOBSERVER] Html.addStyleToElement',
            closeButtonElement,
            rightLeftBottomTopOnResize,
            true
          );
          Html.addStyleToElement(closeButtonElement, rightLeftBottomTopOnResize, true);
        }
      } catch (e) {
        console.error('[SLOTS][STICKY][RESIZEOBSERVER]', e);
        newRelicMetrics.reportError(NewRelicError.STICKY_RESIZE_OBSERVER_ERROR, { message: (e as Error).message });
      }
    });
    resizeObserver.observe(innerHtmlElement);
  }
};

const addSlotPlaceholder = (slot: StickySlot, innerHtmlElement: HTMLElement) => {
  const boundingRect = innerHtmlElement.getBoundingClientRect();
  const currentWidth = boundingRect.width;
  const currentHeight = boundingRect.height;
  const slotPlaceholderWhileSticky = document.createElement('div');
  slotPlaceholderWhileSticky.id = `${slot.innerId}-sticky-placeholder`;
  slotPlaceholderWhileSticky.style.width = `${currentWidth}px`;
  slotPlaceholderWhileSticky.style.height = `${currentHeight}px`;
  slotPlaceholderWhileSticky.classList.value = innerHtmlElement.classList.value;
  innerHtmlElement.parentNode?.insertBefore(slotPlaceholderWhileSticky, innerHtmlElement);
};

const moveSlot = (slot: StickySlot, innerHtmlElement: HTMLElement) => {
  // eslint-disable-next-line no-param-reassign
  slot[id].state.oldStyle = innerHtmlElement.style.cssText;
  let newStyle = slot[id].config.style;
  const props =
    slot[id].config.anchor.value === 'top' ? ['top', 'left', 'right'] : slot[id].config.anchor.value.split('-');
  props.forEach((prop) => {
    newStyle += ` ${prop}: ${slot[id].config.anchor[prop]}px;`;
  });
  // eslint-disable-next-line no-param-reassign
  innerHtmlElement.classList.value = 'baxter-inner-sticky';
  Html.addStyleToElement(innerHtmlElement, { cssText: newStyle }, false);
};

export const stick = (slot: StickySlot, innerHtmlElement: HTMLElement) => {
  console.info('[SLOTS][STICKY][STICK]');
  newRelicMetrics.reportMetric(NewRelicMetric.STICKY_STICK);
  // eslint-disable-next-line no-param-reassign
  slot[id].state.sticky = true;

  addSlotPlaceholder(slot, innerHtmlElement);

  moveSlot(slot, innerHtmlElement);

  setTimeout(() => {
    try {
      addCloseButton(slot, innerHtmlElement);
      ninjaMetrics.reportMetric(NinjaMetric.STICKY_AD_DISPLAYED, {
        ad_unit_id: slot?.[Providers.GOOGLE_ADS]?.initialized?.path,
      });
    } catch (e) {
      console.error('[SLOTS][STICKY][ADDCLOSEBUTTON][TIMEOUT]', e);
      newRelicMetrics.reportError(NewRelicError.STICKY_ADD_CLOSE_BUTTON_TIMEOUT_ERROR, {
        message: (e as Error).message,
      });
    }
  }, 50);
};

const validFrequencyCap = (slot: StickySlot) => {
  const frequencyCapInMs = convertMinutesToMilliseconds(slot[id].config.frequencyCap || 0);
  const lastRendering = Number(localStorage.getItem(getSlotKey(slot))) || 0;
  return Date.now() > lastRendering + frequencyCapInMs;
};

const addResizeObserver = (containerHtmlElement: HTMLElement, stickySlot: StickySlot): void => {
  console.info('[SLOTS][STICKY][ADDRESIZEOBSERVER]');
  // eslint-disable-next-line no-param-reassign
  stickySlot[id].state.resizeObserver = Observer.createResizeObserver(
    containerHtmlElement,
    {
      onResize: (entry) => {
        if (!stickySlot[id].state.stickyClosedByUser) {
          const boundingRect = stickySlot.innerHtmlElement.getBoundingClientRect();
          if (
            !stickySlot[id].state.sticky &&
            boundingRect.height > 0 &&
            boundingRect.top < 0 &&
            -boundingRect.top > entry.contentRect.height / 2 &&
            validFrequencyCap(stickySlot)
          ) {
            stick(stickySlot, stickySlot.innerHtmlElement);
          }
        }
      },
    },
    'STICKY',
    NewRelicError.STICKY_RESIZE_OBSERVER_ERROR
  );
};

const addIntersectionObserver = (containerHtmlElement: HTMLElement, stickySlot: StickySlot): void => {
  console.info('[SLOTS][STICKY][ADDINTERSECTIONOBSERVER]');
  // eslint-disable-next-line no-param-reassign
  stickySlot[id].state.intersectionObserver = Observer.createIntersectionObserver(
    containerHtmlElement,
    {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      onIntersect: (entry) => {
        // eslint-disable-next-line no-param-reassign
        stickySlot[id].state.previousTop = stickySlot[id].state.previousTop ?? 0;
        // eslint-disable-next-line no-param-reassign
        stickySlot[id].state.currentTop = entry.boundingClientRect.top;

        if (!stickySlot[id].state.stickyClosedByUser) {
          if (
            !stickySlot[id].state.sticky &&
            stickySlot[id].state.currentTop < 0 &&
            entry.intersectionRatio < 0.6 &&
            stickySlot[id].state.currentTop < stickySlot[id].state.previousTop &&
            validFrequencyCap(stickySlot)
          ) {
            stick(stickySlot, stickySlot.innerHtmlElement);
          } else if (
            stickySlot[id].state.sticky &&
            entry.intersectionRatio > 0.6 &&
            stickySlot[id].state.currentTop > stickySlot[id].state.previousTop
          ) {
            unstick(stickySlot, stickySlot.innerHtmlElement);
          }
        }
        // eslint-disable-next-line no-param-reassign
        stickySlot[id].state.previousTop = stickySlot[id].state.currentTop;
      },
    },
    'STICKY',
    NewRelicError.STICKY_INTERSECTION_OBSERVER_ERROR
  );
};

const applyToSlot = (containerHtmlElement: HTMLElement, stickySlot: StickySlot): void => {
  console.info('[SLOTS][STICKY][APPLYTOSLOT]', stickySlot);
  addIntersectionObserver(containerHtmlElement, stickySlot);
  addResizeObserver(containerHtmlElement, stickySlot);
};

const apply = (containerHtmlElement: HTMLElement, slot: Slot): boolean => {
  // eslint-disable-next-line no-param-reassign
  slot[id] = {
    config:
      getConfigById(globalThis.Baxter.config?.slots?.settings?.sticky, slot.pageId, slot.containerId, slot.id) || {},
    state: {
      sticky: false,
    },
  };
  if (!slot[id].config.enabled) {
    return false;
  }
  if (slot[id].state.alreadyApplied) {
    return false;
  }
  // eslint-disable-next-line no-param-reassign
  slot[id].state.alreadyApplied = true;
  applyToSlot(containerHtmlElement, slot as StickySlot);
  return true;
};

const remove = (containerHtmlElement: HTMLElement, slot: Slot): void => {
  if (slot[id]?.config?.enabled) {
    console.info('[SLOTS][STICKY][REMOVE]', slot);
    if (slot[id].state.sticky) {
      unstick(slot as StickySlot, slot.innerHtmlElement);
    }
    removeObserver(containerHtmlElement, slot as StickySlot);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.alreadyApplied = false;
  }
};

// eslint-disable-next-line import/no-default-export
export default {
  apply,
  remove,
};
