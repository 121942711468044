import * as Url from 'BaxterScript/helper/string/Url';
import * as Cookie from 'BaxterScript/helper/browser/Cookie';
import * as Request from 'BaxterScript/helper/browser/Request';
import * as Json from 'BaxterScript/helper/object/Json';
import * as Html from 'BaxterScript/helper/browser/Html';
import { OptanonCategory } from 'BaxterScript/version/web/config/OptanonCategory';

const SCRIPT_ID = 'baxter-script-slots';
const PREVIEW_COOKIE_KEY = 'baxter-slots-preview';
const PREVIEW_TOKEN_KEY = 'previewToken';

export const getCookie = () => Json.parse(Cookie.get(PREVIEW_COOKIE_KEY));

export const shouldInitialize = () => getCookie()?.id;

export const init = async () => {
  const { cdnDomain } = globalThis.Baxter.config;
  const { apiDomain } = globalThis.Baxter.config;
  const { accountId } = globalThis.Baxter.config;
  const { appId } = globalThis.Baxter.config;
  const appDirUrl = `${cdnDomain}/${accountId}/${appId}`;

  if (isReleaseScript(appDirUrl)) {
    const previewId = getIdFromUrl();

    if (previewId) {
      const url = `${apiDomain}/account/${accountId}/app/${appId}/preview/${previewId}`;
      let previewData;
      try {
        previewData = Json.parse(await Request.get(url));
      } catch (err) {
        console.error('[SLOTS][PREVIEW][INIT]', err);
      }
      if (previewData) {
        setCookie(previewData);
      } else {
        removeCookie();
      }
      console.debug('[SLOTS][PREVIEW] reload and clear token');
      reloadAndClearToken(previewId);
      return true;
    }
    const previewCookie = getCookie();
    if (previewCookie && previewCookie.id) {
      Html.updateScriptInHead(
        SCRIPT_ID,
        true,
        OptanonCategory.C0001,
        `${appDirUrl}/${previewCookie.versionId}/init.js`
      );
      console.debug('[SLOTS][PREVIEW] add preview overlay');
      addPreviewOverlay(previewCookie.versionId);
      return true;
    }
  }

  return false;
};

const setScriptId = (appDirUrl) => {
  const scripts = document.getElementsByTagName('script');
  for (const script of scripts) {
    if (script.src.startsWith(`${appDirUrl}/release/init.`)) {
      script.id = SCRIPT_ID;
    }
  }
};

const isReleaseScript = (appDirUrl) => {
  if (!Html.getElementById(SCRIPT_ID)) {
    setScriptId(appDirUrl);
  }
  return Html.getScriptUrl(SCRIPT_ID).includes(`${appDirUrl}/release/`);
};

const reloadAndClearToken = (previewId) => {
  globalThis.location.href = globalThis.location.href.replace(`${PREVIEW_TOKEN_KEY}=${previewId}`, '');
};

const getIdFromUrl = () => Url.getQueryStringParam(PREVIEW_TOKEN_KEY, globalThis.location.href);
export const setCookie = (data) => {
  const now = new Date();
  const expiry = now.setHours(now.getHours() + 1);
  Cookie.set(PREVIEW_COOKIE_KEY, JSON.stringify(data), expiry);
};

export const removeCookie = () => {
  Cookie.remove(PREVIEW_COOKIE_KEY);
};

const addPreviewOverlay = (versionId) => {
  const container = document.createElement('div');
  container.id = 'baxter-preview';
  container.style.position = 'fixed';
  container.style.bottom = '0';
  container.style.right = '0';
  container.style.background = 'lightgrey';
  container.style.zIndex = '999999999';
  container.style.padding = '0 5px';
  const content = document.createTextNode(`Baxter v${versionId}`);
  container.appendChild(content);
  document.body.appendChild(container);
};
